import React from "react"

export default ({
  children,
  className = "",
  style = {},
}: {
  children: React.ReactNode
  className?: string
  style?: React.AllHTMLAttributes<HTMLElement>
}) => {

  return (
    <div
      className={["is-card", className].join(" ")}
      style={style}
    >
      {children}
    </div>
  )
}
