import { useEffect } from 'react'

export default ({
  isWide = false
}: {
  isWide?: boolean
} = {}) => {
  useEffect(() => {
    if (isWide) {
      document.body.classList.add('is-wide-page')
    } else {
      document.body.classList.remove('is-wide-page')
    }
  }, [])
}
