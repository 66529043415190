import React from "react"
import "./tiles.css"

export default ({
  children,
  className = ""
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={["tiles-grid__container", className].join(" ")}>
      {children}
    </div>
  )
}
