import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import News from "~/components/News"
import useScrollToTop from "~/hooks/useScrollToTop"
import useMaxWidth from "~/hooks/useMaxWidth"
import Tiles from "~/components/Grid/Tiles"
import FilterNav from "~/components/FilterNav"
import { I18nContext, I18nLink } from "~/components/I18n"
import { IGatsbyImageData } from "gatsby-plugin-image"

export const query = graphql`query ($id: String!) {
  site {
    siteMetadata {
      title
      description
      author
      image
      siteUrl
    }
  }
  allWpCategory(sort: {fields: name}, filter: {count: {gte: 1}}) {
    edges {
      node {
        id
        uri
        name
        count
        language {
          slug
        }
      }
    }
  }
  allWpPost(
    sort: {order: DESC, fields: date}
    filter: {nodeType: {eq: "Post"}, status: {eq: "publish"}, categories: {nodes: {elemMatch: {id: {in: [$id]}}}}}
  ) {
    edges {
      node {
        id
        uri
        date
        title
        excerpt
        featuredImage {
          node {
            title
            caption
            altText
            sourceUrl
            srcSet
            sizes
            mimeType
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    distinct(field: id)
  }
}
`

export default ({
  data: {
    allWpPost,
    allWpCategory,
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
  pageContext: {
    id: categoryId
  }
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  allWpCategory: {
    edges: {
      node: {
        id: string
        name: string
        uri: string
        count: number
        language: {
          slug: string
        }
      }
    }[]
  }
  allWpPost: {
    edges: {
      node: {
        id: string
        uri: string
        date: string
        title: string
        excerpt: string
        featuredImage: {
          node: {
            sourceUrl: string
            srcSet: string
            sizes: string
            mimeType: string
            localFile: {
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData
              }
            }
          }
        }
      }
    }[]
  }
}, {
  id: string
}>) => {
  useScrollToTop()
  useMaxWidth()

  const { locale } = useContext(I18nContext)

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: locale,
          dir: "ltr",
          prefix: "og: http://ogp.me/ns#",
        }}
      >
        <title>{title}</title>
        <link rel="author" href="/humans.txt" />

        <meta name="description" content={description} />
        <meta name="image" content={`${siteUrl}${image}`} />

        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${image}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={author} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${siteUrl}${image}`} />


      </Helmet>


      <header>
        <I18nLink to="/news">
          <h1>News</h1>
        </I18nLink>

        <FilterNav>
          {allWpCategory.edges
          .filter((c) => {
            return c.node.language === null || locale === c.node.language.slug
          })
          .map(({
            node: {
              id, uri, name
            }
          }) => {
            return (
              <Link
                id={id}
                to={uri}
                key={id}
                title={name}
                className={[categoryId === id ? "active" : ""].join(' ')}
              >{name}</Link>
            )
          })}
        </FilterNav>
      </header>

      <section>

        <Tiles className="has-margin-top news-grid">
          {allWpPost.edges
          .filter(({ node: { date } }) => new Date(date) <= new Date())
          .map(({
            node: {
              id, title, uri, date, excerpt, featuredImage
            }
          }) => {
            return (
              <News
                key={id}
                id={id}
                uri={uri}
                title={title}
                subtitle={excerpt}
                date={new Date(date)}
                display={true}
                featuredImage={featuredImage}
              />
            )
          })}
        </Tiles>
      </section>
    </>
  )
}
