import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react"

const FilterNav: FC<PropsWithChildren<{

}>> = ({
  children,
}) => {
    const scrollerRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [showControls, setShowControls] = useState<boolean>(true)

    const scroll = (delta: number) => {
      scrollerRef.current?.scrollBy(delta, 0)
    }

    useEffect(() => {
      if (scrollerRef.current && wrapperRef.current) {
        setShowControls(
          // magic constants, yeah!...
          scrollerRef.current?.scrollWidth > wrapperRef.current?.clientWidth
        )
      }
    }, [
      scrollerRef.current,
      wrapperRef.current,
    ])

    return (
      <div ref={wrapperRef} className="filtering-content-menu is-uppercase">
        {showControls && (
          <button onClick={() => scroll(scrollerRef.current?.clientWidth! / -2)}>
            <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M 8.649 23.059 L 0.649 11.979 L 8.649 0.899" stroke="#000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </button>
        )}

        <nav ref={scrollerRef} className={
          showControls ? 'has-arrow-actions' : ''
        }>
          {children}
        </nav>

        {showControls && (
          <button onClick={() => scroll(scrollerRef.current?.clientWidth! / 2)}>
            <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M 11.278 0.96 L 3.278 12.04 L 11.278 23.12" stroke="#000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" transform="matrix(-1, 0, 0, -1, 14.556, 24.08)"></path>
            </svg>
          </button>
        )}
      </div>
    )
  }

export default FilterNav
