import React from "react";

export default ({
  src,
  alt,
  sources,
}: {
  src: string
  alt: string
  sources: {
    sizes: string
    srcSet: string
    mimeType: string
  }[]
}) => {
  return (
    <picture>
      {sources.map(({sizes, srcSet, mimeType}) => (
        <source srcSet={srcSet} type={mimeType} sizes={sizes} />
      ))}
      <img src={src} alt={alt} className="h-full object-cover" />
    </picture>
  )
}
