import React from "react"
import omit from "~/functions/omit"

export default (props: {
  background?: React.ReactNode
  children: React.ReactNode
  className?: string
  style?: any
}) => {
  const {
    background,
    children,
    className = "",
    style = {},
  } = props

  return (
    <div
      {...omit(props, 'background')}
      className={[
        "is-layered",
        background && "has-background",
        className,
      ].join(" ")}
    >
      <div className="is-background">
        {background}
      </div>

      {children}
    </div>
  )
}
