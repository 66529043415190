import React from "react"
import Card from "../Card"
import { Link, } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Box from "../Box"
import dateformat from "~/functions/dateformat"
import omit from "~/functions/omit"
import Picture from "../Picture";

const News = React.memo((props: {
  id: string
  uri: string
  title: string
  subtitle: string
  date: Date
  display: boolean
  featuredImage: {
    node: {
      sourceUrl: string
      srcSet: string
      sizes: string
      mimeType: string
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }
  }
}) => {
  const {
    uri,
    title,
    subtitle,
    date,
    display,
    featuredImage,
  } = props

  const image = featuredImage?.node

  return (
    <Link to={uri} className={display ? "" : "is-hidden"} {...omit(props, "subtitle", "display", "featuredImage")}>
      <Box
        className="has-half-margin-bottom"
        background={image && (image.localFile?.childImageSharp?.gatsbyImageData ? (
          <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} alt={title} />
        ) : (
          <Picture src={image?.sourceUrl} alt="" sources={[
            image
          ]} />
        ))}
      >
        <Card
          className={[
            "is-align-items-bottom",
            image && "has-bg-image",
          ].join(" ")}
        >
          <article className="has-text-shadow">
            <time dateTime={date.toISOString()} className="is-small">{dateformat(date, 'd mmm yyyy')}</time>
            <h4 className="has-quarter-margin-bottom">{title}</h4>
          </article>
        </Card>
      </Box>
    </Link>
  );
})

News.displayName = "News";

export default News;
