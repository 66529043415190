import { useEffect } from 'react'

export default () => {
  useEffect(() => {
    const div = document.querySelector('.content')
    if (div) {
      div.scrollTo(0, 0)
    }
  }, []);
}
